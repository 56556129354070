import { IShareMethod } from "./types"
import {
  shareOnFacebook,
  shareOnInstagram,
  shareOnMail,
  shareOnTelegram,
  shareOnTwitter,
  shareOnViber,
  shareOnVk,
  shareOnWhatsapp,
} from "./utils"

export const ShareInMessagesBtns: IShareMethod[] = [
  {
    title: "Отправить на Telegram",
    icon: "Telegram",
    variant: "link",
    handle: shareOnTelegram,
  },
  {
    title: "Отправить на WhatsApp",
    icon: "WhatsApp",
    variant: "link",
    handle: shareOnWhatsapp,
  },
  {
    title: "Отправить на Viber",
    variant: "link",
    icon: "Viber",
    handle: shareOnViber,
  },
  {
    title: "Отправить на Почту",
    variant: "link",
    icon: "Email",
    handle: shareOnMail,
  },
  {
    title: "Отправить на Vk",
    variant: "link",
    icon: "Vk",
    handle: shareOnVk,
  },
  {
    title: "Отправить на Instagram",
    icon: "Instagram",
    variant: "link",
    handle: shareOnInstagram,
  },
  {
    title: "Отправить на Twitter",
    icon: "Twitter",
    variant: "link",
    handle: shareOnTwitter,
  },
  {
    title: "Отправить на Facebook",
    icon: "Facebook",
    variant: "link",
    handle: shareOnFacebook,
  },
]

export const TITLE_MODAL_DEFAULT =
  "Вы можете отправить ссылку любым удобным Вам способом"
export const TITLE_DEFAULT =
  "Похоже версия вашего браузера устарела, мы рекомендуем обновить ее до стабильной"
