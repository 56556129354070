import { Companions } from "@/components/Cart/Modals/Companions"
import { COMPANIONS_TITLE } from "@/components/Products/constants"
import { Button } from "@/ui/Button/Button"
import { Modal } from "@/ui/Modal/Modal"
import { RuleSortProduct } from "@/utils/common/helpers"
import type { FC } from "react"

export type ModalCompanionsPropsType = {
  companions?: RuleSortProduct[]
}

export const ModalCompanions: FC<ModalCompanionsPropsType> = ({
  companions,
}) => {
  if (!companions || !companions.length) {
    return null
  }

  return (
    <Modal
      closeMode={"destroy"}
      disclosure={
        <Button variant={"small"} icon={"Transaction"}>
          Сопутствующие
        </Button>
      }
      title={COMPANIONS_TITLE}
      variant={"rounded-100"}
    >
      <Companions items={companions} />
    </Modal>
  )
}
