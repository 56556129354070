import React, { FC, memo } from "react";
import { navigatorShare } from "@/components/SharedText/utils";
import { useClipboardCopy } from "@/hooks/clipboardCopy";
import { colors } from "@/styles/utils/vars";
import { ButtonGroup, cssButtonGroupVertical } from "@/ui/Button/StyledButton";
import { Modal } from "@/ui/Modal/Modal";
import { Popover } from "@/ui/Popover/Popover";
import { Typography } from "@/ui/Typography/Typography";
import { Button } from "components/ui/Button/Button";
import { ShareInMessagesBtns, TITLE_DEFAULT, TITLE_MODAL_DEFAULT } from "./constants";
import { SharedPropsType } from "./types";
const SharedText: FC<SharedPropsType> = memo(({
  titleModal = TITLE_MODAL_DEFAULT,
  share,
  setIsShowed,
  isShowed
}) => {
  const hideHandle = () => {
    setIsShowed(false);
  };
  const {
    handleCopyClick,
    isCopied
  } = useClipboardCopy();
  const copyHandle = () => {
    handleCopyClick(share.url);
  };
  if (typeof navigator !== "undefined" && !!navigator.share && isShowed) {
    // если браузер поддерживает метод navigator.share используем его а если нет то код ниже
    navigatorShare({
      ...share
    });
    hideHandle();
    return <></>;
  }
  return <Modal onClose={hideHandle} variant={"rounded-0"} isShowModal={isShowed} closeMode={"destroy"} title={titleModal}>
        <Typography>{TITLE_DEFAULT}</Typography>

        <ButtonGroup className={cssButtonGroupVertical}>
          {ShareInMessagesBtns.map(({
        icon,
        title,
        handle
      }, index) => <Button key={index} onClick={() => {
        handle(share.url);
        hideHandle();
      }} variant="link" size="small" icon={icon}>
              {title}
            </Button>)}

          <Popover disclosure={<Button onClick={copyHandle} icon={"Copy"} size={"small"} variant={"link"}>
                Просто скопировать
              </Button>} isShow={isCopied} isStylingIconDisclosure={false} size={"small"}>
            <Typography variant={"p12"} color={colors.green}>
              Ссылка скопирована!
            </Typography>
          </Popover>
        </ButtonGroup>
      </Modal>;
});
SharedText.displayName = "SharedText";
export { SharedText };