import { cx } from "@linaria/core";
import { FC, memo } from "react";
import { ModalAnalogs } from "@/components/Cart/Modals/ModalAnalogs";
import { ModalCompanions } from "@/components/Cart/Modals/ModalCompanions";
import { ButtonAddToFavorites } from "@/components/Products/parts/ButtonAddToFavorites";
import { ButtonCreateFavoritesList } from "@/components/Products/parts/ButtonCreateFavoritesList";
import { cssButtonProductToggle } from "@/components/ui/Button/StyledButton";
import { useAuth } from "@/hooks/auth";
import { UseProductReturnType } from "@/hooks/product/types";
import { Button } from "@/ui/Button/Button";
import { ButtonSizeAlias } from "@/ui/Button/constants";
import { ButtonProps } from "@/ui/Button/types";
import { Popover } from "@/ui/Popover/Popover";
import { RuleSortProduct } from "@/utils/common/helpers";
import { StyledPopoverContent } from "../../StyledProduct";
export const PopoverSimilar: FC<Pick<UseProductReturnType, "share"> & {
  productUUID: string;
  companions?: RuleSortProduct[];
  analogs?: RuleSortProduct[];
} & Pick<ButtonProps, "size">> = memo(({
  productUUID,
  companions = [],
  analogs = [],
  share,
  size = ButtonSizeAlias.SMALL
}) => {
  const {
    isAuth
  } = useAuth();
  return <Popover isStylingIconDisclosure={false} placement={"bottom-start"} size="default" disclosure={<Button variant={"box"} className={cx(cssButtonProductToggle)} role={"button"} type={"button"} icon={"MoreHor"} size={size} />} style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }}>
        <StyledPopoverContent>
          {!!companions?.length && <ModalCompanions companions={companions} />}
          {!!analogs?.length && <ModalAnalogs analogs={analogs} />}
          <Button variant={"small"} icon={"Share"} onClick={share}>
            Поделиться
          </Button>
          {isAuth && <ButtonAddToFavorites productUUID={productUUID} />}
          {isAuth && <ButtonCreateFavoritesList />}
        </StyledPopoverContent>
      </Popover>;
});
PopoverSimilar.displayName = "PopoverSimilar";