import { useQuery } from "react-query"
import React, { FC, memo, useCallback, useMemo, useState } from "react"
import { fetchProductsList } from "@/api/productsAPI"
import { ProductCategoriesTags } from "@/components/Products/parts/TagsCategories/Tags"
import { ProductsSlider } from "@/components/Products/Slider"
import {
  PLACEMENT_ARROW,
  VARIANT_ARROW,
} from "@/components/Swiper/SwiperWrapper"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader"
import { Typography } from "@/ui/Typography/Typography"
import {
  filterProductsByCategory,
  RuleSortProduct,
  sortProductsWeightRule,
} from "@/utils/common/helpers"

const Analogs: FC<{
  items: RuleSortProduct[]
}> = memo(({ items }) => {
  const uuids: string[] = items
    .filter((i) => !!i.uuid)
    .map((i) => i.uuid as string)

  const { data: dataAnalogs, isFetching } = useQuery(
    ["products", uuids],
    () =>
      fetchProductsList({
        uuids: uuids.join(","),
      }),
    {
      enabled: uuids.length > 0,
    },
  )

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const products = useMemo(
    () =>
      sortProductsWeightRule({
        products: dataAnalogs || [],
        rules: items,
      }),
    [dataAnalogs, items],
  )

  const productsItems = useMemo(
    () =>
      selectedCategory === null
        ? products
        : filterProductsByCategory({
            products: products,
            category: selectedCategory,
          }),
    [products, selectedCategory],
  )

  const onSelectHandle = useCallback((uuid: string | number | null) => {
    setSelectedCategory(!uuid ? null : `${uuid}`)
  }, [])

  return (
    <>
      <ProductCategoriesTags products={products} onSelect={onSelectHandle} />
      {isFetching && <BaseLoader />}
      {products.length > 0 ? (
        <>
          <ProductsSlider
            ruleSort={items}
            products={productsItems}
            placementArrow={PLACEMENT_ARROW.HORIZONTAL}
            variantArrow={VARIANT_ARROW.ARROW}
            responsiveExtends={{
              [getBreakpointVal(breakpoints.lg)]: {
                slidesPerView: 4,
              },
              0: {
                slidesPerView: 1,
              },
            }}
            disabledOnMobile
          />
        </>
      ) : (
        <Typography variant={"p14"}>Аналогов не найдено</Typography>
      )}
    </>
  )
})

Analogs.displayName = "Analogs"

export { Analogs }
