import { type FC } from "react"
import { CreateListForm } from "@/components/Favorites/CreateListForm"
import { Button } from "@/ui/Button/Button"
import { Modal } from "@/ui/Modal"

export const ButtonCreateFavoritesList: FC = () => (
  <Modal
    variant={"rounded-50"}
    title={"Добавить список"}
    closeMode={"destroy"}
    disclosure={
      <Button variant="small" icon="PlusCircle">
        Создать список
      </Button>
    }
  >
    <CreateListForm />
  </Modal>
)
