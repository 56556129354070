import { FC, memo, useMemo } from "react"
import { TagCategorySlider } from "@/components/Tags/TagCategorySlider"
import { TagCategorySliderPropsType } from "@/components/Tags/types"
import { useAppSelector } from "@/hooks/redux"
import type { ShortCategoryType } from "@/types"
import { getUnique } from "@/utils/common/helpers"
import { ProductDetailListType } from "../../../../../contracts"

type SortedTagsPropsType = {
  products: ProductDetailListType | null
} & Pick<
  TagCategorySliderPropsType,
  "isOutside" | "onSelect" | "defaultSelected"
>

const MULTI_LEVEL = 2
const COND_FOR_MULTI_LEVEL = 6

export const ProductCategoriesTags: FC<SortedTagsPropsType> = memo(
  ({ products, onSelect, isOutside, defaultSelected }) => {
    const categoriesFetched = useAppSelector(
      (state) => state.categories.categories?.fetched || {},
    )

    const tagsCategories: ShortCategoryType[] = useMemo(() => {
      if (!products || products.length <= 0) {
        return []
      }

      if (Object.keys(categoriesFetched).length <= 0) {
        return []
      }

      const productCategoriesIds = getUnique(
        products.reduce(
          (arr: string[], { categories = [] }) => [
            ...arr,
            ...(categories ?? []),
          ],
          [],
        ),
      )

      return productCategoriesIds.reduce((arr: ShortCategoryType[], id) => {
        const fetchProductCat = categoriesFetched[id]
        return !!fetchProductCat
          ? [
              ...arr,
              {
                uuid: fetchProductCat.uuid,
                name: fetchProductCat.name,
                image: fetchProductCat.image,
              },
            ]
          : arr
      }, [])
    }, [categoriesFetched, products])

    const length = tagsCategories.length

    if (length <= 0) {
      return null
    }

    return (
      <TagCategorySlider
        tags={tagsCategories}
        onSelect={onSelect}
        isOutside={isOutside}
        multilevel={length > COND_FOR_MULTI_LEVEL ? MULTI_LEVEL : undefined}
        withStartTag={length > 1}
        defaultSelected={defaultSelected}
      />
    )
  },
)

ProductCategoriesTags.displayName = "Tags"
