import { cx } from "@linaria/core";
import { BaseHTMLAttributes, FC } from "react";
import { cssTag, cssTagCategory, cssTagCategorySelected, StyledImageTag } from "@/components/Tags/StyledTags";
import { TagType } from "@/types";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { Link } from "@/ui/Link";
import { Typography } from "@/ui/Typography/Typography";
export type TagCategoryPropsType = Omit<BaseHTMLAttributes<HTMLDivElement>, "onSelect"> & {
  tag: TagType & {
    uuid?: string;
  };
  selected?: boolean;
  onSelect?: (uuid: string) => void;
};
const TagCategory: FC<TagCategoryPropsType> = ({
  tag: {
    name = "",
    uuid = "",
    image,
    url = ""
  },
  selected = false,
  className,
  onSelect,
  ...props
}) => {
  return <div className={cx(cssTag, cssTagCategory, selected && cssTagCategorySelected, className)} data-id={uuid} onClick={e => {
    e.preventDefault();
    if (typeof onSelect === "function") {
      onSelect(uuid);
    }
  }} {...props}>
      <StyledImageTag>
        <EntityImage alt="" imagePath={image} layout="fill" objectFit="cover" objectPosition="center" />
      </StyledImageTag>
      <Typography variant="span">{name}</Typography>
      {!!url && <Link href={url} title={name} textIsHidden>
          {name}
        </Link>}
    </div>;
};
export { TagCategory };