import { INavigatorShareProps } from "./types"

const openHandle = (url: string) => {
  window.open(url, "_blank")
}

export const shareOnWhatsapp = (text: string) => {
  openHandle(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`)
}

export const shareOnTelegram = (text: string) => {
  openHandle(`https://telegram.me/share/url?url=${encodeURIComponent(text)}`)
}

export const shareOnViber = (text: string) => {
  openHandle(`viber://forward?text=${encodeURIComponent(text)}`)
}

export const shareOnMail = (text: string) => {
  openHandle(`mailto:?subject=&body=${encodeURIComponent(text)}`)
}

export const shareOnInstagram = (text: string) => {
  openHandle(`https://www.instagram.com/share?url=${encodeURIComponent(text)}`)
}

export const shareOnVk = (text: string) => {
  openHandle(`https://vk.com/share.php?url=${encodeURIComponent(text)}`)
}

export const shareOnTwitter = (text: string) => {
  openHandle(`https://twitter.com/intent/tweet?url=${encodeURIComponent(text)}`)
}

export const shareOnFacebook = (text: string) => {
  openHandle(
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(text)}`,
  )
}

export const navigatorShare = ({ title, url, text }: INavigatorShareProps) => {
  if (!navigator || typeof navigator === undefined || !navigator?.share) {
    return
  }
  navigator
    .share({
      title: title,
      url: url,
      text: text,
    })
    .catch((err) => {
      console.log("SharedText error: ", err)
    })
}
