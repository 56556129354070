import type { FC } from "react"
import { useCallback, useEffect, useState } from "react"
import { Analogs } from "@/components/Cart/Modals/Analogs"
import { Button } from "@/ui/Button/Button"
import { Modal } from "@/ui/Modal/Modal"
import { RuleSortProduct } from "@/utils/common/helpers"

export type ModalAnalogsPropsType = {
  analogs?: RuleSortProduct[]
  isShowAnalogs?: boolean
  setIsShowAnalogs?: (val: boolean) => void
}

export const ModalAnalogs: FC<ModalAnalogsPropsType> = ({
  analogs,
  isShowAnalogs = false,
  setIsShowAnalogs,
}) => {
  const [isShow, setIsShow] = useState<boolean>(isShowAnalogs)

  const onCloseHandle = useCallback(() => {
    if (!!setIsShowAnalogs) {
      setIsShowAnalogs(false)
    }
    setIsShow(false)
  }, [setIsShowAnalogs])

  useEffect(() => {
    setIsShow(isShowAnalogs)
  }, [isShowAnalogs])

  if (!analogs || !analogs.length) {
    return null
  }

  return (
    <Modal
      isShowModal={isShow}
      closeMode={"destroy"}
      onClose={onCloseHandle}
      disclosure={
        <Button
          variant={"small"}
          icon={"Copy"}
          onClick={() => {
            setIsShow(true)
          }}
        >
          Аналоги
        </Button>
      }
      title={"Аналоги"}
      variant={"rounded-100"}
    >
      <Analogs items={analogs} />
    </Modal>
  )
}
