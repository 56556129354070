import { useQuery } from "react-query"
import React, { FC, memo, useState } from "react"
import { fetchProductsList } from "@/api/productsAPI"
import { ProductCategoriesTags } from "@/components/Products/parts/TagsCategories/Tags"
import { ProductsSlider } from "@/components/Products/Slider"
import {
  PLACEMENT_ARROW,
  VARIANT_ARROW,
} from "@/components/Swiper/SwiperWrapper"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader"
import { Typography } from "@/ui/Typography/Typography"
import {
  filterProductsByCategory,
  RuleSortProduct,
  sortProductsWeightRule,
} from "@/utils/common/helpers"

const Companions: FC<{
  items: RuleSortProduct[]
}> = memo(({ items }) => {
  const uuids: string[] = items
    .filter((i) => !!i.uuid)
    .map((i) => i.uuid as string)
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const { data: dataCompanions, isFetching } = useQuery(
    ["products", uuids],
    () =>
      fetchProductsList({
        uuids: uuids.join(","),
      }),
    {
      enabled: uuids.length > 0,
    },
  )

  const products = sortProductsWeightRule({
    products: dataCompanions || [],
    rules: items,
  })

  const productsItems =
    selectedCategory === null
      ? products
      : filterProductsByCategory({
          products: products,
          category: selectedCategory,
        })

  return (
    <>
      {isFetching && <BaseLoader />}

      <ProductCategoriesTags
        products={products}
        onSelect={(uuid) => {
          setSelectedCategory(!uuid ? null : `${uuid}`)
        }}
      />

      {!!products.length ? (
        <ProductsSlider
          ruleSort={items}
          products={productsItems}
          placementArrow={PLACEMENT_ARROW.HORIZONTAL}
          variantArrow={VARIANT_ARROW.ARROW}
          responsiveExtends={{
            [getBreakpointVal(breakpoints.lg)]: {
              slidesPerView: 4,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          disabledOnMobile
        />
      ) : (
        <Typography variant={"p14"}>Сопутствующих не найдено</Typography>
      )}
    </>
  )
})

Companions.displayName = "Companions"

export { Companions }
